import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { DescriptionLinkVideoType } from '@shared/types/umbraco';

import Button from 'common/Button';
import Video from 'common/Video';
import Section from 'common/Section';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import './DescriptionLinkVideo.scss';

const DescriptionLinkVideo: FC<DescriptionLinkVideoType.IData> = ({
  description,
  link,
  video,
  color,
  isHorizontal = false,
  isHorizontalReversed,
  isIntroWithButton,
  sectionTheme,
}) => {
  const { link: cta, aliaLabel, icon } = link?.[0]?.properties || {};
  const { url, name } = cta?.[0] || {};

  return (
    <Section
      name="description-link-video"
      color={color}
      {...(!isIntroWithButton && { intro: description })}
      classes={classnames({
        [`${sectionTheme}`]: sectionTheme,
        'description-link-video-section--horizontal': isHorizontal,
        'description-link-video-section--horizontal-reversed': isHorizontalReversed,
        'description-link-video-section--intro-with-button': isIntroWithButton,
      })}
    >
      <>
        {url ? (
          <div className="description-link-video-section__description-wrapper">
            {isIntroWithButton && description ? (
              <DangerouslySetInnerHtml className="section__intro" html={description} />
            ) : null}

            <Button
              to={url}
              key={name}
              ariaLabel={aliaLabel}
              variant="secondary-link"
              iconSuffix={icon[0]}
            >
              {name}
            </Button>
          </div>
        ) : null}

        {!!video?.length && <Video {...video?.[0]?.properties} />}
      </>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentDescriptionLinkVideo on TDescriptionLinkVideo {
    properties {
      video {
        properties {
          description
          time
          vimeoLink
          youtubeLink
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
          btnAriaLabel
        }
      }
      description
      link {
        properties {
          ariaLabel
          icon
          link {
            name
            url
          }
        }
      }
      showInMenu
      isHorizontal
      isHorizontalReversed
      isIntroWithButton
      anchorName
      anchorTitle
      color {
        label
      }
      sectionTheme
    }
    structure
  }
`;

export default DescriptionLinkVideo;
