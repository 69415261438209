import React, { FC, useState, useEffect, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classnames from 'classnames';
import Accordion from 'react-tiny-accordion';

import { Container } from 'layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Icon from 'common/IconCustom';

import { compareUrls } from 'utils/stringUtils/stringUtils';
import { isEmpty } from 'utils/functions';

import { IProductFiltersProps, IFilterState } from './model';

import './ProductFilters.scss';

const ProductFilters: FC<IProductFiltersProps> = ({ title, filters, bgColor, waiSettings }) => {
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const { currentFilter, restFilters }: IFilterState = useMemo(
    () => ({
      currentFilter: filters.find(({ url }) => compareUrls(url, pathname))?.name,
      restFilters: filters.filter(({ url }) => !compareUrls(url, pathname)),
    }),
    [pathname, filters]
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const updateIsMobile = () => setIsMobile(mediaQuery.matches);
    updateIsMobile();
    mediaQuery.addListener(updateIsMobile);

    return () => mediaQuery.removeListener(updateIsMobile);
  }, []);

  return (
    <section
      className={classnames('product-filters', {
        [`product-filters--bg-${bgColor?.label}`]: bgColor?.label,
      })}
    >
      <Container fluid>
        <DangerouslySetInnerHtml className="product-filters__title" html={title} />
        {isMobile && currentFilter && !isEmpty(restFilters) ? (
          <Accordion
            className="product-filters__accordion"
            openClassName="product-filters__accordion--opened"
            transitionDuration="300"
          >
            <div
              className="product-filters__accordion-item"
              data-header={
                <button
                  className="product-filters__toggler"
                  type="button"
                  aria-label={waiSettings.ariaToggle}
                >
                  {currentFilter}
                  <Icon icon="chevron-down" />
                  <Icon icon="chevron-up" />
                </button>
              }
            >
              <ul className="product-filters__accordion-list">
                {restFilters.map(({ name, url }) => (
                  <Link key={url} to={url} className="product-filters__link">
                    {name}
                  </Link>
                ))}
              </ul>
            </div>
          </Accordion>
        ) : !isEmpty(filters) ? (
          <ul className="product-filters__list">
            {filters.map(({ name, url }) => (
              <li key={url}>
                <Link
                  to={url}
                  className="product-filters__link"
                  activeClassName="product-filters__link--active"
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        ) : null}
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductFilters on TProductFilters {
    properties {
      title
      filters {
        name
        url
      }
      bgColor {
        label
      }
    }
    structure
  }
`;

export default ProductFilters;
