import React, { FC, useEffect, useMemo, useState } from 'react';
import './Filters.scss';
import { Configure } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import connectHits from 'react-instantsearch-core/dist/es/connectors/connectHits';
import ConditionalWrapper from 'common/ConditionalWrapper/ConditionalWrapper';
import { ICustomFiltersProps } from './Filters.model';
import { convertCheckboxesToFiltersValue } from './utils';
import { Container } from '../../layout';
import Button from '../../common/Button';
import CustomDropdown from '../CustomDropdown';

const Filters: FC<ICustomFiltersProps> = ({
  hits,
  filters,
  buttonTitle,
  handleResetToShow = () => {},
  sortingOptions,
  onDropdownChange,
  dropDownValue,
  sortPlaceholder,
  clearAllButtonText,
  lang,
}) => {
  const [filtersToggle, setFiltersToggle] = useState(false);
  const [checkboxesState, setCheckboxesState] = useState({});
  const [tagFilters, setTagFilters] = useState([]);
  const [firstClickedCategoryIndex, setFirstClickedCategoryIndex] = useState(null);

  useEffect(() => {
    const tagFilterFlatted = [].concat(...tagFilters);
    if (tagFilterFlatted.length === 0) {
      setFirstClickedCategoryIndex(null);
      handleResetToShow();
    }
  }, [JSON.stringify(tagFilters)]);

  useEffect(() => {
    const filtersValue = convertCheckboxesToFiltersValue(checkboxesState);
    setTagFilters(filtersValue);
  }, [JSON.stringify(checkboxesState)]);

  const handleCheckboxesState = (category, title, filterIndex) => {
    if (!firstClickedCategoryIndex && firstClickedCategoryIndex !== 0) {
      setFirstClickedCategoryIndex(filterIndex);
    }
    const checkboxesStateForChange = { ...checkboxesState };
    checkboxesStateForChange[category] = {
      ...(checkboxesStateForChange[category] || {}),
      [title]: !checkboxesStateForChange?.[category]?.[title],
    };
    setCheckboxesState(checkboxesStateForChange);
  };

  const selectedFiltersCount = useMemo(() => {
    const filtersValue = convertCheckboxesToFiltersValue(checkboxesState);
    const filtersValueFlatted = [].concat(...filtersValue);

    return filtersValueFlatted.length;
  }, [JSON.stringify(checkboxesState)]);

  const resetFilters = () => {
    const newCheckboxesState = { ...checkboxesState };
    Object.keys(newCheckboxesState).forEach((key) => {
      Object.keys(newCheckboxesState[key]).forEach((checkboxesStateKey) => {
        newCheckboxesState[key][checkboxesStateKey] = false;
      });
    });
    setCheckboxesState(newCheckboxesState);
  };

  const resultTags = useMemo(
    () => [
      ...new Set(
        hits
          ?.map((hit) => hit._tags)
          .flat()
          .filter((item) => item)
      ),
    ],
    [JSON.stringify(hits)]
  );

  return (
    <>
      <Configure
        analytics={false}
        distinct
        tagFilters={tagFilters}
        filters={`lang:${lang}`}
        hitsPerPage={200}
      />
      <Container fluid>
        <div className={classNames('filter-sort', { 'no-filters': !filters })}>
          <ConditionalWrapper
            condition={filters?.length > 0}
            wrapper={(children) => (
              <>
                <div className="filter-sort__flex">
                  <div className="filter-sort__toggle-container">
                    <Button
                      variant="link"
                      clickHandler={() => setFiltersToggle(!filtersToggle)}
                      iconPrefix={filtersToggle ? 'minus' : 'plus'}
                      classes="filters-toggle-button"
                      aria-expanded={filtersToggle}
                    >
                      {buttonTitle}
                    </Button>
                    {selectedFiltersCount ? (
                      <span className="filters__count">{` (${selectedFiltersCount})`}</span>
                    ) : null}
                  </div>
                  {filtersToggle ? (
                    <form action="#" className="filter">
                      <div className="filters">
                        {filters.map(({ category, attributes }, filterIndex) => (
                          <fieldset key={category} className="filters__item">
                            <legend className="filters__item__category">
                              {category}
                              <span aria-hidden="true">:</span>
                            </legend>
                            <ul className="filters__item__attributes">
                              {attributes?.map((attribute) => {
                                const disabled =
                                  firstClickedCategoryIndex !== null &&
                                  filterIndex !== firstClickedCategoryIndex &&
                                  !resultTags?.includes(attribute.title);

                                return (
                                  <li
                                    className="filters__item__attributes__checkbox-wrapper"
                                    key={attribute.title}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`attribute-checkbox-${attribute.title}`}
                                      checked={checkboxesState?.[category]?.[attribute.title]}
                                      disabled={disabled}
                                      aria-checked={
                                        !!checkboxesState?.[category]?.[attribute.title]
                                      }
                                      onClick={() =>
                                        handleCheckboxesState(
                                          category,
                                          attribute.title,
                                          filterIndex
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`attribute-checkbox-${attribute.title}`}
                                      className={classNames('filters__item__attributes__item', {
                                        'filters__item__attributes__item--disabled': disabled,
                                      })}
                                    >
                                      <div className="filters__item__attributes__item__title">
                                        {attribute.name}
                                      </div>

                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </fieldset>
                        ))}
                      </div>
                      <Button
                        variant="secondary"
                        classes="filters-clear"
                        disabled={[].concat(...tagFilters).length === 0}
                        onClick={resetFilters}
                      >
                        {clearAllButtonText}
                      </Button>
                    </form>
                  ) : null}
                </div>
                {children}
              </>
            )}
          >
            <CustomDropdown
              dropDownList={sortingOptions}
              onChange={onDropdownChange}
              value={dropDownValue}
              sortPlaceholder={sortPlaceholder}
            />
          </ConditionalWrapper>
        </div>
      </Container>
    </>
  );
};

const FiltersWithHits = connectHits(Filters);

export default FiltersWithHits;

export const query = graphql`
  fragment FragmentFilterList on TFilterList {
    properties {
      filters {
        properties {
          category
          attributes {
            id
            title
            name
          }
        }
        structure
      }
      buttonTitle
    }
    structure
  }
`;
