import React, { FC, useRef, useState, useEffect } from 'react';
import Button from 'common/Button';
import classNames from 'classnames';
import './CustomDropdown.scss';

interface ICustomDropdown {
  dropDownList?: { value: string; title: string }[];
  onChange: (val: string) => void;
  value: string;
  sortPlaceholder?: string;
}

const CustomDropdown: FC<ICustomDropdown> = ({
  dropDownList,
  onChange,
  value,
  sortPlaceholder,
}) => {
  const currentValue = dropDownList?.find((el) => el.value === value) || { title: '', value: '' };
  const [isOpen, setIsOpen] = useState(false);
  const navigationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!navigationRef?.current?.contains(event.target as HTMLElement)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen((prevVal) => !prevVal);
  };

  const handleChange = (changeValue: string) => {
    onChange(changeValue);
    setIsOpen(false);
  };

  const dropdownTitle = sortPlaceholder
    ? `${sortPlaceholder} ${currentValue?.title}`
    : currentValue?.title;

  return dropDownList?.length > 0 ? (
    <div
      ref={navigationRef}
      className={classNames('table-dropdown', {
        'table-dropdown--open': isOpen,
      })}
      data-testid="table-dropdown"
    >
      <Button
        classes="table-dropdown__title"
        variant="primary-link"
        iconSuffix="chevron-down"
        clickHandler={handleClick}
      >
        {dropdownTitle}
      </Button>
      <div className="table-dropdown__list">
        <ul>
          {dropDownList?.map((el) => (
            <li
              key={el.value}
              className={classNames('table-dropdown__item', {
                'table-dropdown__item--disabled': el.value === value,
              })}
            >
              <Button
                classes="table-dropdown__button"
                variant="link"
                onClick={() => handleChange(el.value)}
              >
                {el.title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default CustomDropdown;
