interface DeepArray<T> extends Array<T | DeepArray<T>> {}

export const convertCheckboxesToFiltersValue = (checkboxesState): DeepArray<string> => {
  const tagValues = Object.values(checkboxesState);

  return tagValues.map((tag) => {
    const arrToReturn = [];
    Object.keys(tag).forEach((key) => {
      if (tag[key]) {
        arrToReturn.push(key);
      }
    });

    return arrToReturn;
  });
};
